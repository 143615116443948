import { useTranslation } from 'next-i18next';

import * as Yup from 'yup';

import INPUT_IDS from '@constants/inputs/loginInputs';

import type { FormValues } from './types/FormValues';

export const initialValues: FormValues = {
  [INPUT_IDS.USERNAME]: '',
  [INPUT_IDS.PASSWORD]: '',
  [INPUT_IDS.KEEP_ME_LOGGED_IN]: false,
};

export const validationSchema = () => {
  const { t } = useTranslation();

  return Yup.object({
    [INPUT_IDS.USERNAME]: Yup.string()
      .email(t('$*error.field.email.invalid'))
      .required(t('$*error.field.required')),
    [INPUT_IDS.PASSWORD]: Yup.string().required(
      t('$*error.field.password.required')
    ),
  });
};
