import withUserAuthenticated from '@components/HOC/withUserAuthenticated';
import getServerSideTranslations from '@utils/getServerSideTranslations';
import LoginPage from '@views/Auth/LoginPage/LoginPage';

export const getServerSideProps = withUserAuthenticated(async ({ locale }) => ({
  props: {
    ...(await getServerSideTranslations(locale)),
    bodyClassName: 'page--login',
    locale,
  },
}));

export default LoginPage;
