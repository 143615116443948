import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Trans } from 'next-i18next';

import { motion } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';

import Link from '@components/elements/Link';
import LoginForm from '@components/modules/LoginForm/LoginForm';
import SeoCms from '@components/modules/Seo/SeoCms';
import CUSTOM_SEO_SETTINGS_KEYS from '@constants/customSeoSettingsKeys';
import ROUTE_URLS from '@constants/routeUrls';
import { sanitizeUrl } from '@utils/helpers';

const LoginPage = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleAfterLogin = () => {
    if (!isEmpty(router.query.from)) {
      router.push(sanitizeUrl(router.query.from));
      return;
    }

    router.push(ROUTE_URLS.MY_ORDERS);
  };

  return (
    <>
      <SeoCms
        pageName={t('$*loginPage.pageName')}
        customSeoSettingsKey={CUSTOM_SEO_SETTINGS_KEYS.LOGIN_PAGE}
      />
      <div className="container">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: {
              opacity: 0,
              x: -100,
            },
            visible: {
              opacity: 1,
              x: 0,
              transition: {
                delay: 0.4,
              },
            },
          }}
        >
          <div tw="flex justify-center">
            <div tw="px-5 py-4 bg-white rounded-md max-w-lg w-full shadow-lg md:px-9 md:py-7 lg:px-10 lg:py-8">
              <h1 className="h2" tw="mb-3">
                {t('$*loginPage.title')}
              </h1>
              <p tw="mb-4 text-gray-3 md:mb-5 lg:mb-8">
                {t('$*loginPage.paragraph')}
              </p>
              <LoginForm onAfterLogin={handleAfterLogin} />
              <div tw="mt-10 pt-4 text-center border-t border-gray-1">
                <Trans
                  i18nKey="$*loginPage.notHaveAccount"
                  components={[
                    <Link
                      data-cy="register-url"
                      href={ROUTE_URLS.AUTH_REGISTER}
                      key="0"
                      tw="font-semibold"
                    >
                      {null}
                    </Link>,
                  ]}
                />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default LoginPage;
