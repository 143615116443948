import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useEffect, useRef } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { loginWithUserData } from '@store/auth/auth.actions';
import { useAppDispatch } from '@store/useAppDispatch';
import type { ApiErrorMessages } from '@tsTypes/ApiErrorMessages';
import type { Client } from '@tsTypes/Client';
import { isAxiosError } from 'axios';
import { getCookie, setCookie } from 'cookies-next';
import { addMinutes, differenceInSeconds } from 'date-fns';
import { type FormikHelpers, Form, Formik } from 'formik';
import tw from 'twin.macro';

import Alert from '@components/elements/Alert';
import Button from '@components/elements/Button';
import ExternalAuth from '@components/elements/ExternalAuth/ExternalAuth';
import { isUserDataObject } from '@components/elements/ExternalAuth/helpers/isUserDataObject';
import InputsIndex from '@components/elements/Form/InputsIndex';
import INPUT_IDS from '@constants/inputs/loginInputs';
import useGetApiErrorMessages from '@hooks/useGetApiErrorMessages';
import { format } from '@services/Date.service';
import { pushLoginGTMEvent } from '@utils/gtm';
import { triggerDeclarationEvent } from '@utils/gtmTs';

import type { FormValues } from './types/FormValues';
import { initialValues, validationSchema } from './formikData';
import Link from 'next/link';
import ROUTE_URLS from '@constants/routeUrls';

const DATE_FORMAT = 'yyyy.MM.dd HH:mm:ss.T';
const BRUTE_FORCE_TIME_KEY = 'bruteForceTime';

type LoginFormProps = {
  onAfterLogin: () => void;
};

const LoginForm = ({ onAfterLogin }: LoginFormProps) => {
  const isMounted = useRef(true);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();

  const getApiErrorMessages = useGetApiErrorMessages();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleFormikSubmit = (
    values: FormValues,
    { resetForm, setStatus, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const bruteForceTimeCookie = getCookie(BRUTE_FORCE_TIME_KEY);

    const getBruteforceTimeLeftMessage = (
      bruteForceTimeLeftInSeconds: number
    ) =>
      t('$*error.api.bruteForce.tooManyAttempts', {
        time: `${bruteForceTimeLeftInSeconds} ${t(
          '$*common.secondsAccusative',
          {
            count: bruteForceTimeLeftInSeconds,
          }
        )}`,
      });

    if (bruteForceTimeCookie && typeof bruteForceTimeCookie === 'string') {
      const bruteForceTimeLeftInSeconds = differenceInSeconds(
        new Date(bruteForceTimeCookie),
        new Date()
      );

      setStatus({
        apiErrorMessage: getBruteforceTimeLeftMessage(
          bruteForceTimeLeftInSeconds
        ),
        fieldErrors: {},
      });
      setSubmitting(false);

      return;
    }

    dispatch(
      loginWithUserData({
        ...values,
        type: 'regular',
      })
    )
      .then(unwrapResult)
      .then((value: { userData: Client } | unknown) => {
        if (isUserDataObject(value)) {
          pushLoginGTMEvent({
            provider: 'email',
            ctaType: 'button',
            pagePath: router.asPath,
            userId: value.userData.id,
          });

          if (isMounted.current) {
            setStatus();
            resetForm();
            onAfterLogin();
            triggerDeclarationEvent(value.userData);
          }
        }
      })
      .catch((error: unknown) => {
        if (isAxiosError(error) && typeof error.response === 'object') {
          const { status, data } = error.response;

          const setBruteForceError = (bruteForceTimeInMinutes: number) => {
            const bruteForceTimeLeftInSeconds = bruteForceTimeInMinutes * 60;
            const blockExpirationDate = addMinutes(
              new Date(),
              bruteForceTimeInMinutes
            );
            const blockExpirationDateString = format(
              blockExpirationDate.toISOString(),
              DATE_FORMAT
            );

            setCookie(BRUTE_FORCE_TIME_KEY, blockExpirationDateString, {
              maxAge: bruteForceTimeLeftInSeconds,
            });

            setStatus({
              apiErrorMessage: getBruteforceTimeLeftMessage(
                bruteForceTimeLeftInSeconds
              ),
              fieldErrors: {},
            });
          };

          const bruteForceTimeInMinutes: number | null = data?.time || null;

          if (status === 401) {
            if (typeof bruteForceTimeInMinutes === 'number') {
              setBruteForceError(bruteForceTimeInMinutes);
            } else {
              setStatus({
                apiErrorMessage: t('$*error.api.Invalid credentials.'),
                fieldErrors: {},
              });
            }
          } else {
            setStatus(getApiErrorMessages(error));
          }
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setSubmitting(false);
        }
      });
  };

  return (
    <>
      <ExternalAuth
        onAfterLogin={onAfterLogin}
        orText={t('$*components.loginForm.mailContinue')}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema()}
        onSubmit={handleFormikSubmit}
      >
        {({ isSubmitting, dirty, status, setFieldValue }) => {
          const typedStatus: ApiErrorMessages | undefined = status;

          return (
            <Form data-cy="login-form">
              <InputsIndex
                id={INPUT_IDS.USERNAME}
                label={t('$*input.email.label')}
                autoComplete="username"
                required
                onChange={({ target }) => {
                  setFieldValue(target.name, target.value.toLowerCase());
                }}
              />
              <InputsIndex
                id={INPUT_IDS.PASSWORD}
                type="password"
                label={t('$*input.password.label')}
                autoComplete="current-password"
                required
              />
              <div tw="flex flex-wrap justify-between -mx-2">
                <div tw="px-2">
                  <InputsIndex
                    id={INPUT_IDS.KEEP_ME_LOGGED_IN}
                    type="checkbox"
                    label={t('$*input.keepMeLoggedIn.label')}
                    required={false}
                  />
                </div>
                <div tw="font-semibold px-2">
                  <Link href={ROUTE_URLS.RESET_PASSWORD}>
                    {t('$*loginPage.notRememberPassword')}
                  </Link>
                </div>
              </div>
              {typedStatus?.apiErrorMessage && (
                <Alert styles={{ css: tw`mt-4` }}>
                  {typedStatus.apiErrorMessage}
                </Alert>
              )}
              <Button
                tw="mt-4"
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting || !dirty}
                fullWidth
                data-cy="login-form__submit"
              >
                {t('$*loginPage.logIn')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default LoginForm;
